<template lang="pug">
.tariff-info
  .tariff-info__line
    .label Ваш тариф:
    .value {{ company?.tariff?.title || 'Не указан' }}
  .tariff-info__line
    .label Дата завершения тарифа:
    .value {{ `${ company.dateEndTariff } МСК` || 'Не указана' }}
  .tariff-info__line
    .label Договор:
    router-link.link.value(target="_blank" to="/terms.pdf") Открыть договор
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import useAuthStore from "@/stores/auth/useAuthStore";

export default defineComponent({
  name: "MyTariff",
  setup() {

    const authStore = useAuthStore();
    const { company } = storeToRefs(authStore);

    return {
      company,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}

.tariff-info {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.tariff-info__line {
  display: grid;
  grid-template-columns: 220px 1fr;
  line-height: 20px;

  .label {
    color: var(--main-color-black);
    font-size: 14px;
  }

  .value {
    font-weight: bold;
    font-size: 14px;
  }
}

@media (max-width: 460px) {
  .tariff-info__line {
    display: flex;
    flex-flow: column;
  }
}
</style>
